import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { StudentFollowUpListComponent } from './components/student-follow-up-list/student-follow-up-list.component';
import { provideMSAL, TdoeDsModule, TdoeToastComponent,TdoeAlertComponent, TdoeLayoutComponent} from '@tdoe/design-system';
import { environment } from 'src/environments/environment';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { MsalInterceptor, MsalModule, MsalRedirectComponent } from '@azure/msal-angular';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material.module';
import { StudentFollowUpEditFormComponent } from './components/student-follow-up-edit-form/student-follow-up-edit-form.component';
import { VerticalSpacinatorDirective } from './directives/vertical-spacinator.directive';
import { LoginFailedComponent } from './components/login-failed/login-failed.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { AuthRedirectComponent } from './components/auth-redirect/auth-redirect.component';
import { AboutComponent } from './components/about/about.component';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';

@NgModule({ declarations: [
        AppComponent,
        HomeComponent,
        StudentFollowUpListComponent,
        UnauthorizedComponent,
        StudentFollowUpEditFormComponent,
        VerticalSpacinatorDirective,
        LoginFailedComponent,
        NotFoundComponent,
        AuthRedirectComponent,
        AboutComponent
    ],
    bootstrap: [AppComponent, MsalRedirectComponent], imports: [BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        TdoeDsModule,
        TdoeAlertComponent,
        TdoeLayoutComponent,
        AppRoutingModule,
        ToastrModule.forRoot({
            toastComponent: TdoeToastComponent
        }),
        MsalModule,
        NgxSpinnerModule,
        BrowserAnimationsModule,
        MaterialModule], providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true
        },
        {
            provide: LocationStrategy,
            useClass: HashLocationStrategy
        },
        // provideHttpClient(withInterceptors([lockoutInterceptor])),
        provideHttpClient(withInterceptorsFromDi()),
        provideMSAL(environment.authConfig),
    ] })
export class AppModule { }
