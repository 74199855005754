import { Component, OnInit } from '@angular/core';
import { EventService, InactivityService, NavEvent} from '@tdoe/design-system';
import { AppService } from './services/app-service/app.service';
import { FlyoutCommand, NavItem } from '@tdoe/design-system/lib/_models';
import { ClientConfiguration } from './interfaces/clientConfiguration';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  title = '';
  flyoutCommands: FlyoutCommand[] = [];
  navItems: NavItem[] = [];

  constructor(private appService: AppService,
              private readonly _inactivityService: InactivityService,
              private readonly msalService: MsalService) {


  }

  public async ngOnInit(): Promise<void> {
        this.appService.initialize();
    console.log('AppComponent -> ngOnInit -> Enter');
    this._inactivityService.startMonitoring();
    await this.msalService.instance.initialize();
    console.log('AppComponent -> constructor -> Exit');
  }

  onClientConfig(config?: ClientConfiguration): void {
    if (config) {
      this.title = config.appName;
      this.flyoutCommands = [
        { text: `Client: v${config.clientVersion}` },
        { text: `API: v${config.apiVersion}` }
      ];
    }
  }

}
